import React from 'react'
import * as s from "../Styles";

function Header() {


  return (
    <s.HeaderWrapper>
    <s.Logo src='/images/logo.png' alt='logo'/>


    <s.IconsWrapper>
    <a href='https://opensea.io/' rel='noreferrer'  target={'_blank'} >
    <s.Icons src='/images/os.svg' style={{filter: 'invert(1)'}}  alt='logo'/>
    </a>
    <a href='https://twitter.com/888NEKOCLUB' rel='noreferrer' target={'_blank'}>
    <s.Icons src='/images/twt.png'  alt='logo'/>
    </a>
    </s.IconsWrapper>
    </s.HeaderWrapper>
  )
}

export default Header